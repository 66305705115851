import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangeUserRolePutRequestDto, UserPatchPlanRequestDto, UserPatchRequestDto, UserTableDto } from '../model/dtos';
import { ConfigService } from './config.service';
import { InterceptorHttpParams } from '../helpers/InterceptorHttpParams';

@Injectable({
   providedIn: 'root'
})
export class UserService {
   private headers: HttpHeaders;

   constructor(
      private http: HttpClient,
      private configService: ConfigService
   ) {
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
   }

   public getAllUsers(): Observable<UserTableDto> {
      return this.http.get<UserTableDto>(`${this.configService.baseApiUrl}users`);
   }

   public getUserById(id: string) {
      return this.http.get(`${this.configService.baseApiUrl}users/${id}`);
   }

   public changeRole(userId: string, dto: ChangeUserRolePutRequestDto) {
      return this.http.put(`${this.configService.baseApiUrl}users/${userId}/roles`, dto, {
         headers: this.headers
      });
   }

   public createUser(user) {
      return this.http.post(`${this.configService.baseApiUrl}users/`, user, { headers: this.headers });
   }

   public patchUserPlan(userId: string, dto: UserPatchPlanRequestDto, suppressNotification: boolean = false) {
      return this.http.patch(`${this.configService.baseApiUrl}users/${userId}/plan`, dto, {
         headers: this.headers,
         params: new InterceptorHttpParams({ suppressNotification })
      });
   }

   public patchUser(userId: string, dto: UserPatchRequestDto) {
      return this.http.patch(`${this.configService.baseApiUrl}users/${userId}/`, dto, { headers: this.headers });
   }

   public enableUser(userId: string) {
      return this.http.post(`${this.configService.baseApiUrl}users/${userId}/enable`, { headers: this.headers });
   }

   public deleteUser(userId: string, forceDelete: boolean) {
      return this.http.delete(`${this.configService.baseApiUrl}users/${userId}?forceDelete=${forceDelete}`, { headers: this.headers });
   }

   public disableUser(userId: string, suppressNotification: boolean = false) {
      return this.http.post(
         `${this.configService.baseApiUrl}users/${userId}/disable`,
         { headers: this.headers },
         { params: new InterceptorHttpParams({ suppressNotification }) }
      );
   }

   public confirmUserEmail(userId: string) {
      return this.http.post(`${this.configService.baseApiUrl}users/${userId}/confirm-email`, { headers: this.headers });
   }

   public getpendingusers() {
      return this.http.get(`${this.configService.baseApiUrl}users/pending`);
   }
}
