import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupDto, GroupMembersPutRequestDto, GroupUser } from '../model/dtos';
import { ConfigService } from './config.service';

@Injectable({
   providedIn: 'root'
})
export class GroupService {
   private headers: HttpHeaders;

   constructor(
      private http: HttpClient,
      private configService: ConfigService
   ) {
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
   }

   public getAllGroups(): Observable<GroupDto[]> {
      return this.http.get<GroupDto[]>(`${this.configService.baseApiUrl}groups`);
   }

   public updateGroup(dto: GroupDto) {
      return this.http.put(`${this.configService.baseApiUrl}groups/${dto.id}`, dto);
   }

   public createGroup(dto) {
      return this.http.post(`${this.configService.baseApiUrl}groups`, dto, { headers: this.headers });
   }

   public getGroupUsersByGroupId(groupId: string): Observable<GroupUser[]> {
      return this.http.get<GroupUser[]>(`${this.configService.baseApiUrl}groups/${groupId}/users`, { headers: this.headers });
   }

   public addUsersToGroup(groupId: string, dto: GroupMembersPutRequestDto) {
      return this.http.put(`${this.configService.baseApiUrl}groups/${groupId}/users`, dto, { headers: this.headers });
   }

   public addUserToGroup(groupId: string, userId: string) {
      return this.http.post(`${this.configService.baseApiUrl}groups/${groupId}/users/${userId}`, {}, { headers: this.headers });
   }

   public deleteUserFromGroup(groupId: string, userId: string) {
      return this.http.delete(`${this.configService.baseApiUrl}groups/${groupId}/users/${userId}`, { headers: this.headers });
   }

   public deleteGroup(groupId: string) {
      return this.http.delete(`${this.configService.baseApiUrl}groups/${groupId}`, { headers: this.headers });
   }
}
