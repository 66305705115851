import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
   providedIn: 'root'
})
export class SnackService {
   constructor(
      public snackBar: MatSnackBar,
      private zone: NgZone
   ) {}

   public openSuccessSnackBar(message: string, duration = 5000) {
      return this.zone.run(() => {
         const snackBar = this.snackBar.open(message, '', {
            duration,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['success.snack.bar']
         });
         return snackBar;
      });
   }

   public openErrorSnackBar(message: string) {
      return this.zone.run(() => {
         const snackBar = this.snackBar.open(message, '', {
            duration: 10000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error.snack.bar']
         });
         return snackBar;
      });
   }
}
