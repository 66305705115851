import { HttpParams } from '@angular/common/http';

export class InterceptorHttpParams extends HttpParams {
   constructor(
      public interceptorConfig: { suppressNotification },
      params?: { [param: string]: string | string[] }
   ) {
      super({ fromObject: params });
   }
}
