import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from 'app/model/enums';
import { Observable } from 'rxjs';
import {
   BoardTemplateFileDto,
   BoardTemplateFilePatchRequestDto,
   BoardTemplateFilePutRequestDto,
   inviteToOrgLinkShare,
   Org,
   OrganizationDto,
   OrgInvites,
   OrgTableDto,
   ReportVerificationDto,
   ThirdPartyConnectionDto,
   UserDto
} from '../model/dtos';
import { ImportGroupToOrg } from '../model/importGroupToOrg';
import { importUserToOrg } from '../model/importUserToOrg';
import { LdapSettingsDto } from '../model/ldap-settings-dto';
import { ConfigService } from './config.service';
import { InterceptorHttpParams } from '../helpers/InterceptorHttpParams';

@Injectable({
   providedIn: 'root'
})
export class OrgService {
   private headers: HttpHeaders;

   constructor(
      private http: HttpClient,
      private configService: ConfigService
   ) {
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
   }

   public getBoardTemplateFiles(organizationId: string): Observable<BoardTemplateFileDto[]> {
      return this.http.get<BoardTemplateFileDto[]>(`${this.configService.baseApiUrl}organizations/${organizationId}/boardtemplatefiles`);
   }

   public patchBoardTemplateFile(
      organizationId: string,
      id: string,
      dto: BoardTemplateFilePatchRequestDto
   ): Observable<BoardTemplateFileDto> {
      return this.http.patch<BoardTemplateFileDto>(
         `${this.configService.baseApiUrl}organizations/${organizationId}/boardtemplatefiles/${id}`,
         dto,
         { headers: this.headers }
      );
   }

   public getUserByOrgId(id: string): Observable<UserDto[]> {
      return this.http.get<UserDto[]>(`${this.configService.baseApiUrl}organizations/${id}/members`);
   }

   public deleteBoardTemplateFile(organizationId: string, id: string) {
      return this.http.delete(`${this.configService.baseApiUrl}organizations/${organizationId}/boardtemplatefiles/${id}`, {
         headers: this.headers
      });
   }

   public createBoardTemplateFile(organizationId: string, file: File, progressCallback: any = null): Promise<BoardTemplateFileDto> {
      return new Promise<BoardTemplateFileDto>((resolve, reject) => {
         const formData = new FormData();
         formData.append(file.name, file);

         const uploadReq = new HttpRequest(
            'POST',
            `${this.configService.baseApiUrl}organizations/${organizationId}/boardtemplatefiles`,
            formData,
            {
               reportProgress: true
            }
         );

         const subscription = this.http.request(uploadReq).subscribe((event) => {
            if (event.type === HttpEventType.UploadProgress && progressCallback) {
               progressCallback(event, Math.round((100 * event.loaded) / event.total));
            } else if (event.type === HttpEventType.Response) {
               resolve(<BoardTemplateFileDto>event.body);
            }
         }, reject);
      });
   }

   public putBoardTemplateFile(
      organizationId: string,
      id: string,
      dto: BoardTemplateFilePutRequestDto,
      file: File,
      progressCallback: any = null
   ): Promise<BoardTemplateFileDto> {
      return new Promise<BoardTemplateFileDto>((resolve, reject) => {
         const formData = new FormData();
         formData.append('name', dto.name);
         formData.append('description', dto.description);
         formData.append(file.name, file);

         const uploadReq = new HttpRequest(
            'PUT',
            `${this.configService.baseApiUrl}organizations/${organizationId}/boardtemplatefiles/${id}`,
            formData,
            {
               reportProgress: true
            }
         );

         const subscription = this.http.request(uploadReq).subscribe((event) => {
            if (event.type === HttpEventType.UploadProgress && progressCallback) {
               progressCallback(event, Math.round((100 * event.loaded) / event.total));
            } else if (event.type === HttpEventType.Response) {
               resolve(<BoardTemplateFileDto>event.body);
            }
         }, reject);
      });
   }

   getAllOrgs(): Observable<OrgTableDto> {
      return this.http.get<OrgTableDto>(`${this.configService.baseApiUrl}Organizations/?listMode=Table`);
   }

   getPendingOrgInvitesById(id): Observable<OrgInvites[]> {
      return this.http.get<OrgInvites[]>(`${this.configService.baseApiUrl}Organizations/${id}/invites`, { headers: this.headers });
   }

   getOrgThirdPartyConnections(id: string): Observable<ThirdPartyConnectionDto[]> {
      return this.http.get<ThirdPartyConnectionDto[]>(`${this.configService.baseApiUrl}Organizations/${id}/auth/connections`);
   }

   getOrgStatsById(id: string): Observable<Org> {
      return this.http.get<Org>(`${this.configService.baseApiUrl}Organizations/${id}/Stats`);
   }

   public getOrgById(id: string): Observable<OrganizationDto> {
      return this.http.get<OrganizationDto>(`${this.configService.baseApiUrl}Organizations/${id}`);
   }

   updateOrg(org: Org) {
      return this.http.put(`${this.configService.baseApiUrl}Organizations/${org.orgId}`, org);
   }

   createOrg(org) {
      return this.http.post(`${this.configService.baseApiUrl}Organizations/`, org, { headers: this.headers });
   }

   invite(organizationId: string, InviteToOrganizationRequestDto: inviteToOrgLinkShare) {
      return this.http.post(
         `${this.configService.baseApiUrl}Organizations/${organizationId}/members/invite`,
         InviteToOrganizationRequestDto,
         { headers: this.headers }
      );
   }

   import(organizationId: string, importUserToOrgDto: importUserToOrg) {
      return this.http.post(`${this.configService.baseApiUrl}Organizations/${organizationId}/members/import`, importUserToOrgDto, {
         headers: this.headers
      });
   }

   importGroup(organizationId: string, importGroupToOrgDto: ImportGroupToOrg) {
      return this.http.post(`${this.configService.baseApiUrl}Organizations/${organizationId}/members/importGroup`, importGroupToOrgDto, {
         headers: this.headers
      });
   }

   addMember(organizationId: string, userId: string, suppressNotification: boolean = false) {
      return this.http.post(
         `${this.configService.baseApiUrl}Organizations/${organizationId}/members/${userId}/add`,
         {
            headers: this.headers
         },
         { params: new InterceptorHttpParams({ suppressNotification }) }
      );
   }

   removeMember(organizationId: string, userId: string) {
      return this.http.post(`${this.configService.baseApiUrl}Organizations/${organizationId}/members/${userId}/remove`, {
         headers: this.headers
      });
   }

   makeOrgAdmin(organizationId: string, userId: string) {
      const permission = Role.OrgAdmin;
      return this.http.post(`${this.configService.baseApiUrl}Organizations/${organizationId}/members/${userId}/permission/${permission}`, {
         headers: this.headers
      });
   }

   removeOrgAdmin(organizationId: string, userId: string) {
      const permission = Role.OrgAdmin;
      return this.http.post(
         `${this.configService.baseApiUrl}Organizations/${organizationId}/members/${userId}/permission/${permission}/delete`,
         { headers: this.headers }
      );
   }

   saveOrganizationLdap(ldapSettings: LdapSettingsDto): Observable<LdapSettingsDto> {
      return this.http.post<LdapSettingsDto>(`${this.configService.baseApiUrl}OrganizationLdap`, ldapSettings, { headers: this.headers });
   }

   deleteOrganizationLdap(organizationLdapId: string): Observable<number> {
      return this.http.delete<number>(`${this.configService.baseApiUrl}OrganizationLdap/${organizationLdapId}`, { headers: this.headers });
   }

   getOrganziationLdap(organizationId: string): Observable<LdapSettingsDto[]> {
      return this.http.get<LdapSettingsDto[]>(`${this.configService.baseApiUrl}OrganizationLdap/${organizationId}`);
   }

   testOrganizationLdapSettings(ldapSettings: LdapSettingsDto): Observable<string> {
      return this.http.post(`${this.configService.baseApiUrl}OrganizationLdap/OrganizationLdapTest`, ldapSettings, {
         headers: this.headers,
         responseType: 'text'
      });
   }

   createAndDownloadAdminReport(organizationId: string) {
      return this.http
         .post(`${this.configService.baseApiUrl}organizations/${organizationId}/report`, {}, { observe: 'response', responseType: 'blob' })
         .toPromise();
   }

   verifyReportIntegrity(reportZip: File) {
      const formData = new FormData();
      formData.append('report', reportZip);
      formData.append('folder', '/');
      return this.http.post<ReportVerificationDto>(`${this.configService.baseApiUrl}Organizations/report/verify`, formData).toPromise();
   }

   renewAndResendOrganizationMemberInvite(orgInvite: OrgInvites) {
      return this.http.post(
         `${this.configService.baseApiUrl}organizations/${orgInvite.organizationId}/members/invites/${orgInvite.id}/resend`,
         {}
      );
   }
}
