import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InviteCreateRequestDto, InviteDto, InvitePatchRequestDto } from '../model/dtos';
import { InviteType } from '../model/enums';
import { ConfigService } from './config.service';

@Injectable({
   providedIn: 'root'
})
export class InviteService {
   private headers: HttpHeaders;

   constructor(
      private http: HttpClient,
      private configService: ConfigService
   ) {
      this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
   }

   public deleteInvite(inviteId: string): Observable<any> {
      return this.http.delete<any>(`${this.configService.baseApiUrl}invites/${inviteId}`);
   }

   public getInvites(type: InviteType = null): Observable<InviteDto[]> {
      if (type) return this.http.get<InviteDto[]>(`${this.configService.baseApiUrl}invites/?type=${type}`);

      return this.http.get<InviteDto[]>(`${this.configService.baseApiUrl}invites/`);
   }

   public updateInvite(inviteId: string, dto: InvitePatchRequestDto): Observable<any> {
      return this.http.patch<any>(`${this.configService.baseApiUrl}invites/${inviteId}`, dto);
   }

   public createInvite(dto: InviteCreateRequestDto): Observable<InviteDto> {
      return this.http.post<InviteDto>(`${this.configService.baseApiUrl}invites/`, dto);
   }
}
