import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
   providedIn: 'root'
})
export class AccountService {
   constructor(
      private http: HttpClient,
      private configService: ConfigService
   ) {}

   register(user) {
      return this.http.post(`${this.configService.baseApiUrl}auth/register`, user);
   }

   /* delete(id) {
         return this.http.delete(`${this.baseUrl}/users/${id}`);
     }*/
}
